import {
  CashBoxTransfer,
  ICashBoxDetailWPO,
  IConfig,
  IExpensesConcept,
  IExpensesSupplier,
  IGifCard,
  INotification,
  IProduct,
  IPurchaseOrder,
  IResponse,
  IResultPagination,
  ISale,
  ISupplier,
  ITablajeria,
  IWarehouse,
  ProductPO,
  User,
  XmlResponse,
} from "../common/interfaces";
import { request } from "./AjaxService";
import Swal, { SweetAlertIcon } from "sweetalert2";
import "@sweetalert2/theme-dark/dark.css";
const baseApi = process.env.REACT_APP_API_URL;
export const CommonService = {
  login: async (user: {
    usuario: string;
    password: string;
  }): Promise<IResponse<User>> => {
    const response = await fetch(`${baseApi}login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return await response.json();
  },
  validateLogin: async (user: {
    usuario: string;
    password: string;
  }): Promise<IResponse<User>> => await request.post(`login-validate`, user),
  logout: async (id: string): Promise<IResponse<User>> => {
    const response = await fetch(`${baseApi}logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });
    return await response.json();
  },
  getProductionProcess: async (): Promise<Array<ITablajeria>> => {
    const response = await fetch(`${baseApi}productions-process?simple=1`);
    return await response.json();
  },
  getTablajeriaProcess: async (): Promise<Array<ITablajeria>> => {
    const response = await fetch(`${baseApi}board-process?simple=1`);
    return await response.json();
  },
  addTablajeriaProcess: async (tablajeria: any): Promise<IResponse<any>> => {
    const response = await fetch(`${baseApi}board-process-log`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...tablajeria }),
    });
    return await response.json();
  },
  addProductionsProcess: async (products: any): Promise<IResponse<any>> => {
    const response = await fetch(`${baseApi}productions-process-log/multiple`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...products }),
    });
    return await response.json();
  },

  addTranserProcess: async (products: any): Promise<IResponse<any>> => {
    const response = await fetch(`${baseApi}transfer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...products }),
    });
    return await response.json();
  },
  confirmTransfer: async (id: number, data: any): Promise<IResponse<any>> => request.put(`transfer/${id}/confirm`, {body: data}),
  cancelTransfer: async (id: number): Promise<IResponse<any>> => request.put(`transfer/${id}/cancel`),
  getTransferByCashBox: async (id: number): Promise<IResponse<Array<CashBoxTransfer>>> => request.get(`transfer/cashbox/${id}`),
  getGifCardInformation: async (code: string): Promise<IResponse<IGifCard>> => {
    const response = await fetch(`${baseApi}gifcard/${code}`);
    return await response.json();
  },

  getTicketSaleInformation: async (id: number): Promise<IResponse<any>> => {
    const response = await fetch(`${baseApi}tickets/${id}/sale`);
    return await response.json();
  },
  getTicketOrderInformation: async (id: number): Promise<IResponse<any>> => {
    const response = await fetch(`${baseApi}tickets/${id}/order`);
    return await response.json();
  },
  getTicketBill: async (id: number): Promise<IResponse<any>> =>
    await request.get(`expenses/${id}/print`),

  getOpenChashDrawerInformation: async (): Promise<IResponse<any>> => {
    const response = await fetch(`${baseApi}open-cash-drawer`);
    return await response.json();
  },

  getTicketCloseBox: async (id: number): Promise<IResponse<any>> => {
    const response = await fetch(`${baseApi}cash-box/${id}/print`);
    return await response.json();
  },

  getTicketWithdraw: async (id: number): Promise<IResponse<any>> => {
    const response = await fetch(`${baseApi}report/cash-box/movements/${id}`);
    return await response.json();
  },
  getTicketPurchaseOrderReceipt: async (id: number): Promise<IResponse<any>> =>
    await request.get(`tickets/${id}/por`),
  getTicketPurchaseOrder: async (id: number): Promise<IResponse<any>> =>
    await request.get(`tickets/${id}/po`),
  getTicketPurchaseOrderPayment: async (id: number): Promise<IResponse<any>> =>
    await request.get(`tickets/${id}/po/payment`),

  getExchangeRate: async (): Promise<IResponse<any>> => {
    const response = await fetch(`${baseApi}get-exchange-rate`);
    return await response.json();
  },
  printTicketPOR: async (id: number) =>
    await CommonService.generatePrintCommand(id, "por"),
  printTicketPO: async (id: number) =>
    await CommonService.generatePrintCommand(id, "po"),
  printTicketSale: async (id: number) =>
    CommonService.generatePrintCommand(id, "sale"),
  printTicketOrder: async (id: number) =>
    CommonService.generatePrintCommand(id, "order"),
  printTicketWithdraw: async (id: number) =>
    CommonService.generatePrintCommand(id, "withdraw"),
  printTicketCloseBox: async (id: number) =>
    CommonService.generatePrintCommand(id, "closeBox"),
  openCashDrawer: async () =>
    CommonService.generatePrintCommand(0, "openCashDrawer"),
  getAmountCash: async (id: number): Promise<IResponse<any>> => await request.get(`cash-box/${id}/cash`),

  generatePrintCommand: async (id: number, process: string) => {
    console.log(
      "Generando Comandos de Impresion, Proceso: ",
      process,
      " Id: ",
      id
    );
    const URLPlugin = "http://localhost:9100/htbin/kp.py";
    try {
      console.log({ URLPlugin });
      let ticketInfo = {} as IResponse<any>;
      if (process === "sale") {
        ticketInfo = await CommonService.getTicketSaleInformation(id);
      }
      if (process === "order") {
        ticketInfo = await CommonService.getTicketOrderInformation(id);
      }
      if (process === "openCashDrawer") {
        ticketInfo = await CommonService.getOpenChashDrawerInformation();
      }
      if (process === "withdraw") {
        ticketInfo = await CommonService.getTicketWithdraw(id);
      }
      if (process === "closeBox") {
        ticketInfo = await CommonService.getTicketCloseBox(id);
      }
      if (process === "por") {
        ticketInfo = await CommonService.getTicketPurchaseOrderReceipt(id);
      }
      if(process === "po") {
        ticketInfo = await CommonService.getTicketPurchaseOrder(id);
      }
      if(process === "pop") {
        ticketInfo = await CommonService.getTicketPurchaseOrderPayment(id);
      }
      if (process === "bill") {
        ticketInfo = await CommonService.getTicketBill(id);
        console.log({ ticketInfo });
      }
      if (!ticketInfo.object.ticket) {
        return;
      }
      const response = await fetch(URLPlugin, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
      });
      const impresoras = await response.json();
      if (impresoras["Tickets"]) {
        // const encodedString = Buffer.from('your string here').toString('base64');
        const data: Record<string, any> = {
          p: "Tickets",
          data: ticketInfo.object.ticket,
        };

        const formBody = [];
        for (let property in data) {
          const encodedKey = encodeURIComponent(property);
          const encodedValue = encodeURIComponent(data[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }

        const response1 = await fetch(URLPlugin, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "POST",
          body: formBody.join("&"),
        });
        console.log("body", await response1.text());
        return;
      }
      throw new Error("NO esta la impresora conectada o compartida");
    } catch (error) {
      console.log({ error });
    }
  },

  getSuppliers: async (): Promise<Array<ISupplier>> =>
    await request.get("catalog/proveedor"),
  getSupplierById: async (id: number): Promise<ISupplier> =>
    await request.get(`catalog/proveedor/${id}`),
  getProductsBySupplier: async (
    id: number,
    params?: Record<string, any>
  ): Promise<IResultPagination<IProduct>> =>
    await request.get(`products`, { ...params, provider_id: id }),
  getExpensesSuppliers: async (): Promise<Array<IExpensesSupplier>> =>
    await request.get("catalog/billsSuppliers"),
  getExpensesConcepts: async (): Promise<Array<IExpensesConcept>> =>
    await request.get("catalog/billsConcepts"),
  getPO: async (params: Record<string, any>): Promise<Array<IPurchaseOrder>> =>
    await request.get("purchase-orders", params).then((e) => e.data),
  createPOReceipt: async (
    id: number,
    payload: Record<string, any>
  ): Promise<IResponse<any>> =>
    await request.post(`purchase-orders/${id}/receipt`, payload),
  createPO: async (payload: Record<string, any>): Promise<IResponse<any>> =>
    await request.post(`purchase-orders/pos`, payload),
  createExpenses: async (
    payload: Record<string, any>
  ): Promise<IResponse<any>> => await request.post("expenses", payload),
  requestAuthorize: async (
    payload: Record<string, any>
  ): Promise<IResponse<any>> =>
    await request.post("notification/send", payload),
  cancelRequestAuthorize: async (
    payload: Record<string, any>
  ): Promise<IResponse<any>> =>
    await request.put(`notification/${payload.id}/cancel`, payload),
  displayAlert: async (message: string, type?: SweetAlertIcon) => {
    return Swal.fire({
      title: message,
      icon: type,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  },
  displayLoader: async (message: string) => {
    return new Promise<typeof Swal>((resolve) => {
      Swal.fire({
        title: message,
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          resolve(Swal);
        },
      });
    });
  },
  toast: async (message: string, type?: SweetAlertIcon) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
    return Toast.fire({
      icon: type,
      title: message,
    });
  },
  transferPendingOrders: async (
    fromCashBox: string,
    toCashBox: string
  ): Promise<IResponse<any>> =>
    await request.post("transfer-pending-orders", { fromCashBox, toCashBox }),
  getScheduleOrders: async (warehouseId: string): Promise<Array<any>> => await request.get(`sales/schedule/${warehouseId}`),
  createPOBySupplier: async (supplierId: number, warehouseId: string): Promise<IResponse<Array<ProductPO>>> => await request.get(`purchase-orders/supplier/${supplierId}/${warehouseId}`),
  getPoToPayInPos: async (cashBoxId: string): Promise<IResponse<Array<ICashBoxDetailWPO>>> => await request.get(`purchase-orders/pos/${cashBoxId}/to-pay`),
  payPurchaseOrder: async (id: number): Promise<IResponse<IPurchaseOrder>> => await request.post(`purchase-orders/pos/${id}/pay`, {}),
  printTicketPoPayment: async (idPo: number) => CommonService.generatePrintCommand(idPo, "pop"),
  getPosConfig: async (): Promise<IResponse<Array<IConfig>>> => await request.get(`config/pos`),
  getSaleInfo: async (id: number): Promise<IResponse<ISale>> => await request.get(`sales/${id}/complete`),
  getNotificationByUserId: async (userId: string): Promise<IResponse<Array<INotification>>> => await request.get(`notification/user/${userId}`),
  readNotification: async (id: string): Promise<IResponse<INotification>> => await request.put(`notification/${id}/read`),
  readAllNotification: async (userId: string): Promise<IResponse<any>> => await request.put(`notification/user/${userId}/read-all`),
  getPendingMovements: async (id: string): Promise<IResponse<{n: number}>> => await request.get(`cash-box/${id}/pending-movements`),
  getXmlByUUID: async (uuid: string): Promise<IResponse<XmlResponse>> => await request.get(`invoice/${uuid}`),
};
