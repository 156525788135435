import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocalStorage } from "../auth/useLocalStorage";
import { CashBoxService } from "../services/CashBoxService";
import { TChashBoxDetails } from "../common/interfaces";
import { CommonService } from "../services/CommonService";
import { ILogin } from "../common/interfaces";
import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.css"
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
interface CloseBoxProps {
  handleCancel: () => void;
}

export const CloseBox = (props: CloseBoxProps) => {
  const { handleCancel } = props;
  const [user, setUser] = useLocalStorage("user", null);
  const [, setTransfer] = useLocalStorage("transfer", null);
  const [steps, setSteps] = useState(0);
  const [newUser, setNewUser] = useState<ILogin>()
  const form = useRef<HTMLFormElement>();

  const handleCloseBox = (event?: any) => {
    event?.preventDefault();
    CashBoxService.getMovements(user.cbox.id, 1).then((res) => {
      if (res.success) {
        if (res.object && res.object.length > 0 && !newUser?.username) {
          if (res.object.some((item: TChashBoxDetails) => !item.payments)) {
            // CommonService.displayAlert(
            //   "No se puede cerrar la caja porque hay movimientos pendientes"
            // );
            setSteps(1);
            return;
          }
        }
        const data = new FormData(form?.current);
        const info: Record<string, any> = {
          usd: data.get("usd")?.toString() ?? 0,
          cash: data.get("cash")?.toString() ?? 0,
          vales: data.get("vales")?.toString() ?? 0,
          card: data.get("card")?.toString() ?? 0,
          cash_box_id: user?.cbox.id ?? "",
          user_id: user?.id ?? "",
        };

        CashBoxService.closeBox(info).then((res) => {
          if (res.success) {
            CommonService.printTicketCloseBox(user?.cbox.id).then(() => {
              console.log("imprimiendo ticket");
              CommonService.logout(user?.id).then(() => {
                localStorage.clear();
                if(newUser?.username){
                  setTransfer({user: newUser, cashBoxId: user.cbox.id})
                }
                window.location.reload();
              });
            });
          }
        });
      }
    });
  };

  const handleTranslateOrders = async (): Promise<void> => {
    const data = new FormData(form?.current);
    const user = data.get('username') as string;
    const pass = data.get('pass') as string;
    const { success, message } = await CommonService.validateLogin({usuario: user, password: pass})
    if(success){
      setNewUser({username: user, password: pass})
      return;
    }
    CommonService.toast(message, "error")
  }
  useEffect(() => {
    if(newUser?.username){
      setSteps(0)
      // handleCloseBox()
    }
  }, [newUser])
  useEffect(() => {
    CommonService.getPendingMovements(user.cbox.id).then((res) => {
      if (res.success) {
        console.log({ res });
        if (res.object?.n && res.object?.n > 0) {
          CommonService.displayAlert("Tienes movimientos pendientes, no puedes cerrar la caja", "error")
          handleCancel()
          return 
        }
        CashBoxService.getMovements(user.cbox.id, 1).then((res) => {
          if (res.success) {
            if (res.object && res.object.length > 0 && !newUser?.username) {
              if (res.object.some((item: TChashBoxDetails) => !item.payments)) {
                setSteps(1);
                return;
              }
            }
          }
        })
      }else{
        CommonService.toast(res.message, "error")
        handleCancel()
      }
    })
  }, [])
  return (
    <Box component="form" onSubmit={handleCloseBox} noValidate sx={{ mt: 1 }} ref={form}>
      {steps === 0 && (
        <>
          <Typography variant="h6" component="h2" gutterBottom>
            Por favor haga el arqueo / conteo de caja y teclee los valores
            correspondientes
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="cash"
                label="Efectivo"
                name="cash"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="cards"
                label="Tarjetas"
                name="card"
                autoFocus
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="cards"
                label="Dolares"
                name="usd"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="cardss"
                label="Vales"
                name="vales"
                autoFocus
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => handleCancel()}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2, ml: 3 }}
          >
            Guardar y Salir
          </Button>
        </>
      )}
      {steps === 1 && (
        <>
          <Typography variant="h6" component="h2" gutterBottom>
            Hay pedidos pendientes de cerrar, para trasladar estos pedidos al
            siguiente cajero, por favor escriba los datos de inicio de sesión.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="user"
                label="Usuario"
                name="username"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="pass"
                type="password"
                label="Contraseña"
                name="pass"
              />
            </Grid>
            
          </Grid>
          <Button
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => handleCancel()}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            sx={{ mt: 3, mb: 2, ml: 3 }}
            onClick={() => handleTranslateOrders()}
          >
            Continuar
          </Button>
        </>
      )}
    </Box>
  );
};
