import {
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CommonService } from "../services/CommonService";
import { useLocalStorage } from "../auth/useLocalStorage";
import {
  IDetails,
  IExpensesConcept,
  IExpensesSupplier,
  IPurchaseOrder,
} from "../common/interfaces";
import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.css"
import withReactContent from "sweetalert2-react-content";
import {v4} from "uuid"

interface ExpensesProps {
  handleCancel: () => void;
}

export const Expenses = (props: ExpensesProps) => {
  const { handleCancel } = props;
  const [amount, setAmount] = useState(0);
  const [code, setCode] = useState("");
  const [user, setUser] = useLocalStorage("user", null);
  const [maxAmount, setMaxAmount] = useState(0);
  const [suppliers, setSuppliers] = useState<IExpensesSupplier[]>();
  const [poList, setPOList] = useState<any[]>();
  const [pO, setPO] = useState<any>();
  const [selectedPO, setSelectedPO] = useState<IPurchaseOrder>();
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [step, setStep] = useState("first");
  const [selectedSupplier, setSelectedSupplier] = useState<string[]>([]);
  const [selectedConcept, setSelectedConcept] = useState<string[]>([]);
  const [supplier, setSupplier] = useState<IExpensesSupplier>();
  const [filter, setFilter] = useState("");
  const [allSuppliers, setAllSuppliers] = useState<IExpensesSupplier[]>();
  const [allConcepts, setAllConcepts] = useState<IExpensesConcept[]>();
  const [notes, setNotes] = useState("");
  const [serie, setSerie] = useState("");
  const [folio, setFolio] = useState("");
  const [uuid, setUUID] = useState("");
  const [iva, setIva] = useState(0);
  const [search, setSearch] = useState("");
  const [listItems, setListItems] = useState<IExpensesConcept[]>();
  const [total, setTotal] = useState(0);
  const style = {
    width: "100%",
    maxWidth: 360,
    border: "1px solid #fff",
    // bgcolor: 'background.paper',
  };
  const MySwal = withReactContent(Swal);
  function ccyFormat(num: number) {
    return `${num.toFixed(2)}`;
  }
  const handleStep = () => {
    if (selectedSupplier.length === 0) {
      MySwal.fire({
        icon: "error",
        toast: true,
        position: "top-end",
        title: "Debe seleccionar un proveedor para continuar",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    changeStep("second");
  };
  const changeStep = (step: string) => setStep(step);
  const execute = async () => {
    const {success, object: {amount: availableCash}} = await CommonService.getAmountCash(user?.cbox.id);
    if(!success){
      CommonService.displayAlert("No tienes suficientes fondos en caja para continuar", "error")
      return 
    }
    if(amount > availableCash){
      CommonService.displayAlert("No tienes suficientes fondos en caja para continuar", "error")
      return;
    }
    CommonService.getAmountCash(user.cbox.id ?? 0).then((res) => {
      if(res.success){
        setMaxAmount(parseFloat(res.object.amount));
      }
    })
    if (total > 0 && selectedConcept[0]) {
      MySwal.fire({
        title: "Confirmar Gasto",
        icon: "question",
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if(result.dismiss){
          return
        }
        CommonService.createExpenses({
          concept_id: selectedConcept[0],
          supplier_id: selectedSupplier[0],
          user_id: user?.id,
          notes,
          serie,
          folio,
          iva,
          amount,
          total,
          cashBoxId: user?.cbox.id,
        }).then(async (res) => {
          if (res.success) {
            MySwal.fire({
              icon: "success",
              toast: true,
              position: "top-end",
              title: "Gasto Creado con éxito",
              showConfirmButton: false,
              timer: 1500,
            });
            handleCancel()
            clean()


            const idAuth = v4();
            const concepto = listItems?.find(e => e.id === Number(selectedConcept[0]))
            CommonService.requestAuthorize({
              message: `Se solicita Autorizar un Gasto $ ${total.toFixed(2)}`, // => $req->message,
              action: {
                type: "authorize-bill",
                item: { bill: res.object.id },
                id: idAuth,
                content: `Se solicita Autorizar un Gasto Total: $ ${total.toFixed(2)} <br/> Detalles: <br /> ${concepto?.name}`,
              }, // => $req->action,
              status: "pending", // => 'pending', // Set the initial status
              from: user.id, // => $req->from,
              to: 1, // => $req->to,
            }).then(() => {
              CommonService.toast(
                "Se envío solicitud de Autorización, para validar Gasto",
                "success"
              );
              handleCancel();
            });

            // CommonService.generatePrintCommand(res.object.id, 'bill').then(e => console.log(e))

            // CommonService.printTicketPOR(res.object.id).then((e) =>
            //   console.log(e)
            // );
            return;
          }
          MySwal.fire({
            icon: "error",
            toast: true,
            position: "top-end",
            title: "Error al crear la recepción",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      });
    }
  };
  const clean = () => {
    setSelectedSupplier([]);
    setSelectedConcept([]);
    cleanForm();
  };
  const cleanForm = () => {
    setAmount(0);
    setSearch("");
    setNotes("");
    setSerie("");
    setFolio("");
    setIva(0);
  };
  const updateObject = () => {
    if (!selectedPO) return;
    let total = 0;
    const newPo = selectedPO;
    newPo.details.map((element) => {
      element.total = (
        Number(element.price) * (1 + iva) * Number(element.quantity) -
        Number(element.discount)
      ).toFixed(2);
      element.tax = (Number(element.total) * iva).toFixed(2);
      return element;
    });
    total = selectedPO.details.reduce<number>(
      (acc, element) =>
        acc +
        (Number(element.price) * Number(element.quantity) -
          Number(element.discount)),
      0
    );

    console.log({ total });
    setSelectedPO({
      ...newPo,
      total: total.toFixed(2),
      grand_total: (total * (1 + iva)).toFixed(2),
      tax: (total * iva).toFixed(2),
    });
  };
  useEffect(() => {
    CommonService.getExpensesSuppliers().then((res) => {
      setSuppliers(res);
      setAllSuppliers(res);
    });
    CommonService.getExpensesConcepts().then((res) => {
      setListItems(res);
      setAllConcepts(res);
    });
  }, []);

  useEffect(() => {
    if (step === "second") {
      CommonService.getPO({
        custom: `supplier_id=${selectedSupplier},warehouse_id=${user?.warehouse_id}`,
      }).then((res) => {
        console.log({ res });
        setPOList(
          res.filter((item: IPurchaseOrder) => item.status === "Aprobado")
        );
      });
    }
  }, [step]);
  useEffect(() => {
    if (selectedSupplier[0] !== "") {
      setSupplier(
        suppliers?.find((item) => item.id === Number(selectedSupplier))
      );
    }
  }, [selectedSupplier]);
  useEffect(() => {
    const results = allSuppliers?.filter((e) =>
      e.name.toLowerCase().includes(filter.trim().toLowerCase())
    );
    setSuppliers(results);
  }, [filter]);

  useEffect(() => {
    const results = allConcepts?.filter((e) =>
      e.name.toLowerCase().includes(search.trim().toLowerCase())
    );
    setListItems(results);
  }, [search]);

  useEffect(() => {
    updateObject();
  }, [iva]);

  useEffect(() => {
    console.log("Calculate Total");
    if (amount > 0) {
      setTotal(amount * (1 + iva));
      return;
    }
    setTotal(0);
  }, [amount, iva]);

  function deleteItem(item: IDetails): void {
    const changePO = selectedPO;
    const index: number = Number(
      changePO?.details.findIndex((e) => e.id === item.id)
    );
    console.log({ index, item }, changePO?.details);
    if (index !== -1 && changePO) {
      const arr = changePO?.details;
      arr?.splice(index, 1);
      changePO.details = arr;
      setSelectedPO({ ...changePO });
    }
  }

  return (
    <>
      {step === "first" && (
        <div style={{ textAlign: "center" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  style={{ width: "100%" }}
                  label="Busque su proveedor"
                  variant="outlined"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Grid>
              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel shrink htmlFor="select-multiple-native">
                  Proveedores
                </InputLabel>
                <Select
                  inputProps={{
                    id: "select-multiple-native",
                  }}
                  labelId="demo-simple-select-label"
                  // id="demo-simple-select"
                  value={selectedSupplier}
                  name="supplier"
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  multiple
                  native
                  onChange={(e) => setSelectedSupplier([e.target.value[0]])}
                >
                  {suppliers &&
                    suppliers.map((item: IExpensesSupplier, index: number) => (
                      <option value={item.id} key={index}>
                        {item.id} - {item.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div style={{ marginTop: "20px" }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => handleCancel()}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              size="large"
              style={{ marginLeft: "20px" }}
              onClick={() => handleStep()}
            >
              Siguiente
            </Button>
          </div>
        </div>
      )}
      {step === "second" && (
        <div style={{ textAlign: "center" }}>
          <Typography style={{ textAlign: "left" }}>
            Proveedor: {supplier?.name}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                <InputLabel htmlFor="serieField">Serie</InputLabel>
                <Input
                  id="serieField"
                  value={serie}
                  onChange={(e) => setSerie(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                <InputLabel htmlFor="folioField">Folio</InputLabel>
                <Input
                  id="folioField"
                  value={folio}
                  onChange={(e) => setFolio(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="searchField">
                  Busque su Concepto
                </InputLabel>
                <Input
                  id="searchField"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </FormControl>
              <div style={{ height: "30px" }}></div>
              <InputLabel htmlFor="select-multiple-native">
                Conceptos
              </InputLabel>
              <Select
                inputProps={{
                  id: "select-multiple-native",
                }}
                labelId="demo-simple-select-label"
                value={selectedConcept}
                name="supplier"
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                multiple
                native
                fullWidth
                onChange={(e) => setSelectedConcept([e.target.value[0]])}
              >
                {listItems &&
                  listItems.map((item, index: number) => (
                    <option value={item.id} key={index}>
                      {item.id} - {item.name}
                    </option>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                <InputLabel htmlFor="subtotal">Subtotal</InputLabel>
                <Input
                  id="subtotal"
                  value={amount}
                  onChange={(e) => setAmount(Number(e.target.value))}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
                <InputLabel htmlFor="iva">IVA</InputLabel>
                <Select
                  inputProps={{
                    id: "iva",
                  }}
                  labelId="iva"
                  value={iva}
                  name="iva"
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  native
                  variant="standard"
                  onChange={(e) => setIva(Number(e.target.value))}
                >
                  <option value={0}> 0 %</option>
                  <option value={0.08}> 8 %</option>
                  <option value={0.16}> 16 %</option>
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} style={{ textAlign: "right" }}>
              <TextField
                id="standard-multiline-static"
                label="Observaciones"
                multiline
                rows={4}
                style={{ width: "100%" }}
                variant="outlined"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Grid> */}
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <h2>TOTAL: $ {ccyFormat(total)} </h2>
            </Grid>
          </Grid>
          <div style={{ marginTop: "20px" }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                clean();
                handleCancel();
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{ marginLeft: "20px" }}
              onClick={() => {
                cleanForm();
                setStep("first");
              }}
            >
              Atras
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{ marginLeft: "20px" }}
              onClick={() => execute()}
            >
              Confirmar Gasto
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
