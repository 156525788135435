import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CommonService } from "../services/CommonService";
import { CashBoxService } from "../services/CashBoxService";
import { useLocalStorage } from "../auth/useLocalStorage";
import { usePusher } from "../context/pusherContext";
import { v4 } from "uuid";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.css"
const MySwal = withReactContent(Swal);

interface PayOrderProps {
  // total?: number;
  // paymentMethod: string;
  // setPaymentMethod: (value: string) => void;
  // amountToPayChash: number;
  // saleId: number;
  handleCancel: () => void;
  // openTicket: (idSale: number) => void;
}

export const Withdraw = (props: PayOrderProps) => {
  const { handleCancel } = props;
  const [amount, setAmount] = useState(0);
  const [code, setCode] = useState('')
  const [user, setUser] = useLocalStorage("user", null);
  const [maxAmount, setMaxAmount] = useState(0)
  const pusher = usePusher();
  useEffect(() => {
    CommonService.getAmountCash(user.cbox.id ?? 0).then((res) => {
      if(res.success){
        setMaxAmount(parseFloat(res.object.amount));
      }
    })
  }, []);

  const execute = async () => {
    
    if(amount > maxAmount){
      setCode('')
      setAmount(0)
      await MySwal.fire({
        icon: "error",
        title: 'EL MONTO A RETIRAR ES MAYOR AL MONTO DISPONIBLE',
        showConfirmButton: true,
        // timer: 1500,
      });
      return
    }
    if(amount === 0){
      setCode('')
      setAmount(0)
      await MySwal.fire({
        icon: "error",
        title: 'EL MONTO A RETIRAR DEBE SER MAYOR A 0',
        showConfirmButton: true,
        // timer: 1500,
      });
      return
    }
    const idCancel = v4();
    let idReq = 0;
    const channel = pusher.subscribe("general-channel");
    // const channel = pusher.subscribe("general-channel");
    const response = await MySwal.fire({
      icon: "info",
      title: 'Se envió solicitud para aprobar el Retiro, se notificara cuando se autorice',
      showConfirmButton: false,
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timer:3000,
      timerProgressBar: true,
      didOpen: () => {
        CommonService.requestAuthorize({
          message: `Se solicita Aprobar retiro Monto: $ ${amount}`, // => $req->message,
          action: {
            type: "authorize-withdraw",
            item: undefined,
            id: idCancel,
            content: `Se solicita Aprobar retiro Monto: $ ${amount}`,
          }, // => $req->action,
          status: 'pending', // => 'pending', // Set the initial status
          from: user.id, // => $req->from,
          to: 1, // => $req->to,
        }).then((res) => {
          
          if(res.success){
            idReq = Number(res.object.id)
            channel.bind("push-notification", async (event: { object: { to?: any; status: string; id: string; }; }) => {
              console.log({event})
              
              if (event.object?.to === user.id && event.object?.status === 'authorized' && event.object?.id === idCancel) {
                CashBoxService.createMovement({
                  'amount' : amount,
                  'cashBox' : user?.cbox.id ?? "",
                }).then((res) => {
                  if(res.success){
                    CommonService.openCashDrawer().then((res) => {
                      console.log("ABRIENDO CAJON");
                    });
                    CommonService.printTicketWithdraw(res.object.id).then((res) => {
                      console.log('Ticket')
                    })
                  }
                })
              }
              if (event.object?.to === user.id && event.object.status === 'declined' && event.object.id === idCancel) {
                await MySwal.fire({
                  icon: "error",
                  title: 'Retiro no autorizado',
                  // showConfirmButton: true,
                  timer: 1500,
                });
                channel.unbind("push-notification");
                channel.unsubscribe();
                return 
              }
    
              channel.unbind("push-notification");
              channel.unsubscribe();

              MySwal.fire({
                icon: "success",
                title: 'Retiro Autorizado',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                timer: 1500,
                width: "300px"
              })
              return;
            
            });
          }
        })
      }
    }).then((res) => {
      handleCancel()
    })
    console.log('reponse', response)
    // if(response.isDismissed && response.dismiss === 'cancel' as unknown as Swal.DismissReason ){
    //   CommonService.cancelRequestAuthorize({
    //     message: ``, // => $req->message,
    //     id: idReq,
    //     status: 'pending', // => 'pending', // Set the initial status
    //     from: user.id, // => $req->from,
    //     to: 1, // => $req->to,
    //   }).then((res) => console.log('Cancelando AUTH'))
    //   setCode('')
    //   setAmount(0)
    //   channel.unbind("push-notification");
    //   channel.unsubscribe();
    //   return
    // }
  }

  return (
    <div style={{ textAlign: "center" }}>
      <Typography>Total a Retirar:</Typography>

      <FormControl sx={{ m: 1 }}>
        <InputLabel htmlFor="outlined-adornment-amount">
          Monto a Retirar
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          label="Amount"
          type="number"
          value={amount}
          onChange={(event) => {
            setAmount(parseFloat(event.target.value));
          }}
        />
      </FormControl>
      {/* <FormControl sx={{ m: 1 }}>
        <InputLabel htmlFor="outlined-adornment-amount">
          Código de Autorización
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          label="Code"
          type="password"
          value={code}
          onChange={(event) => {
            setCode(event.target.value);
          }}
        />
      </FormControl> */}
      <div style={{ marginTop: "20px" }}>
        <Button variant="contained" size="large" onClick={() => handleCancel()}>
          Cancelar
        </Button>

        <Button
          variant="contained"
          size="large"
          style={{ marginLeft: "20px" }}
          onClick={() => execute()}
        >
          Retirar
        </Button>
      </div>
    </div>
  );
};
