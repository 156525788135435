import * as React from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useLocalStorage } from "../auth/useLocalStorage";
import { InventoryService } from "../services/InventoryService";
import { useEffect } from "react";

import AsyncSelect from "react-select/async";
// import { ColourOption, colourOptions } from '../data';
import { productsService } from "../services/productsService";
import { CashBoxTransfer, Details, IProduct, IPurchaseOrder, Product } from "../common/interfaces";
import { CommonService } from "../services/CommonService";

interface CloseBoxProps {
  handleCancel: () => void;
}

export default function TransferInventory(props: CloseBoxProps) {
  const { handleCancel } = props;
  const [user, setUser] = useLocalStorage("user", null);
  const [warehouse, setWarehouses] = React.useState(
    `${user?.warehouse_id}` ?? ""
  );
  const [warehouseDestiny, setWarehousesDestiny] = React.useState("");
  const [warehouseList, setWarehouseList] = React.useState<any[]>([]);
  const [products, setProducts] = React.useState<IProduct[]>([]);
  const [productsToTransfer, setProductsToTransfer] = React.useState<any[]>([]);
  const [productsTablajeo, setProductsTablajeo] = React.useState<
    Record<string, any>
  >({});
  const [step, setStep] = React.useState("first");
  const [item, setItem] = React.useState<CashBoxTransfer>();
  const [transferList, setTransferList] = React.useState<any[]>([]);
  const promiseOptions = async (inputValue: string) => {
    const response = await productsService.getProductsByWarehouse(
      inputValue,
      user.warehouse_id
    );
    setProducts(response.data);
    return response.data.map((product: IProduct) => ({
      value: product.id,
      label: product.name,
    }));
  };
  const handleSelectProduct = (e: any) => {
    const newProduct: IProduct = products.find(
      (p) => p.id === e.value
    ) as IProduct;
    const index = productsToTransfer.findIndex((p) => p.id === newProduct.id);
    console.log({ index });
    if (index === -1) {
      setProductsToTransfer([...productsToTransfer, newProduct]);
      setProductsTablajeo({
        ...productsTablajeo,
        [newProduct.id.toString()]: "",
      });
    }
  };

  const removeItem = (id: any) => {
    const index = productsToTransfer.findIndex((p) => p.id === id);
    if (index !== -1) {
      const aux = productsToTransfer;
      aux.splice(index, 1);
      setProductsToTransfer([...aux]);
    }
  };

  useEffect(() => {
    InventoryService.getWarehouses().then((res) => {
      if (res.length > 0) {
        setWarehouseList(res);
      }
    });
    CommonService.getTransferByCashBox(user.cbox.id).then((res) => {
      if (res.success) {
        setTransferList(res?.object ?? []);
      }
    });
    console.log({ user });
  }, []);

  useEffect(() => {
    console.log({ warehouse });
  }, [warehouse]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const aux = [];
    for (let product in productsTablajeo) {
      if (productsTablajeo[product] === "") {
        CommonService.displayAlert(
          "Debe ingresar la cantidad de cada producto"
        );
        return;
      }
      aux.push({
        id: product,
        quantity: productsTablajeo[product],
      });
    }
    const data = {
      products: aux,
      user_id: user.id,
      origin_warehouse_id: warehouse,
      destiny_warehouse_id: warehouseDestiny,
      status: "Completado",
      comments: "Creado POS",
      is_pos: 1,
      cash_box_id: user.cbox.id,
    };
    CommonService.addTranserProcess(data).then((res) => {
      CommonService.displayAlert(res.message);
      if (res.success) {
        handleCancel();
      }
    });
  };
  return (
    <>
      {step === "first" && (
        <div style={{ textAlign: "center" }}>
          <Typography style={{ textAlign: "left" }}>
            <Button
              style={{ float: "right" }}
              hidden={true}
              onClick={() => setStep("createTransfer")}
            >
              Crear Transferencia de Inventario
            </Button>
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <List style={{ width: "100%" }} component="div">
                {transferList &&
                  transferList.map((item: any) => (
                    <div key={item.id} style={{ width: "100%" }}>
                      <ListItem
                        button
                        onClick={() => {
                          if (item.transfer.status !== "Pendiente") {
                            CommonService.displayAlert(
                              "Transferencia Completada"
                            );
                            return;
                          }
                          if (
                            item.transfer.origin_warehouse_id ===
                            user.warehouse_id
                          ) {
                            CommonService.displayAlert(
                              "No puedes transferir a tu mismo almacen"
                            );
                            return;
                          }
                          setItem(item);
                          setStep("confirmTransfer");
                        }}
                        style={{ width: "100%" }}
                      >
                        <div style={{ width: "100%" }}>T - {item.id}</div>
                        <div style={{ width: "300px" }}>
                          {item.transfer.status === "Pendiente" ? (
                            <div style={{ color: "red" }}>Pendiente</div>
                          ) : (
                            <div style={{ color: "green" }}>Completado</div>
                          )}
                        </div>

                        {/* <ListItemText primary={`PO - ${item.id} `} /> */}
                      </ListItem>
                      <Divider />
                    </div>
                  ))}
                {transferList?.length === 0 && (
                  <Typography>No hay Transferencias Pendientes</Typography>
                )}
              </List>
            </Grid>
          </Grid>
          <div style={{ marginTop: "20px" }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => handleCancel()}
            >
              Cancelar
            </Button>
          </div>
        </div>
      )}
      {step === "createTransfer" && (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Typography>Transferencia de Inventario</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel id="demo-simple-select-label">
                  Almacen Origen
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="warehouse"
                  required
                  value={warehouse}
                  onChange={(e) => {
                    if (e.target.value === warehouseDestiny) {
                      setWarehousesDestiny("");
                      CommonService.displayAlert(
                        "El Almacen de Destino no puede ser igual al Almacen de Origen"
                      );
                    } else setWarehouses(e.target.value);
                  }}
                  label="warehouse"
                >
                  {warehouseList.length > 0 &&
                    warehouseList
                      .filter((warehouse) => warehouse.id === user.warehouse_id)
                      .map((warehouse) => (
                        <MenuItem value={warehouse.id} key={warehouse.id}>
                          {warehouse.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth style={{ marginTop: 20 }}>
                <InputLabel id="demo-simple-select-label">
                  Almacen Destino
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="warehouse"
                  required
                  value={warehouseDestiny}
                  onChange={(e) => {
                    if (e.target.value === warehouse) {
                      setWarehousesDestiny("");
                      CommonService.displayAlert(
                        "El Almacen de Destino no puede ser igual al Almacen de Origen"
                      );
                    } else setWarehousesDestiny(e.target.value);
                  }}
                  label="warehouse"
                >
                  {warehouseList.length > 0 &&
                    warehouseList
                      .filter((warehouse) => warehouse.id !== user.warehouse_id)
                      .map((warehouse) => (
                        <MenuItem value={warehouse.id} key={warehouse.id}>
                          {warehouse.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {warehouseDestiny !== "" && warehouse !== "" && (
            <>
              <hr />
              <AsyncSelect
                cacheOptions
                defaultOptions
                onChange={handleSelectProduct}
                loadOptions={promiseOptions}
                placeholder="Busque su Producto"
                styles={{
                  control: (styles) => ({ ...styles }),
                  placeholder: (styles) => ({ ...styles, color: "#000" }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      ...styles,
                      backgroundColor: isDisabled
                        ? undefined
                        : isSelected
                        ? "#ccc"
                        : isFocused
                        ? "#ccc"
                        : undefined,
                      color: isDisabled ? "#ccc" : isSelected ? "#333" : "#333",
                    };
                  },
                }}
              />
            </>
          )}
          <table
            className="table table-striped"
            style={{ marginTop: 20, width: "100%" }}
          >
            {productsToTransfer.map((item: IProduct, index: number) => (
              <tr key={"item" + index}>
                <td>
                  <IconButton
                    aria-label="delete"
                    onClick={() => removeItem(item.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </td>
                <td>
                  <Typography>{item.name}</Typography>
                </td>
                <td>
                  <TextField
                    margin="normal"
                    type="number"
                    required
                    fullWidth
                    id="cards"
                    value={productsTablajeo[item.id.toString()]}
                    onChange={(e) => {
                      setProductsTablajeo({
                        ...productsTablajeo,
                        [item.id.toString()]: e.target.value,
                      });
                    }}
                    label="Cantidad a transferir"
                    name="quantity"
                    autoFocus
                  />
                </td>
              </tr>
            ))}
          </table>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {
                setStep("first");
              }}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, ml: 3 }}
            >
              Guardar y Salir
            </Button>
          </div>
        </Box>
      )}
      {step === "confirmTransfer" && (
        <>
          <div style={{ textAlign: "center" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Producto</TableCell>
                    <TableCell>Cantidad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    item && item.transfer.details.map((detail: Details) => (
                      <TableRow>
                        <TableCell>{detail.product.name}</TableCell>
                        <TableCell>{detail.quantity}</TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>

            <div style={{ marginTop: "30px" }}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => props.handleCancel()}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    CommonService.confirmTransfer(item?.transfer?.id ?? 0, {user_id: user.user.id}).then(res => {
                      if(res.success){
                        CommonService.displayAlert("Transferencia Completada")
                        props.handleCancel()
                      }
                    })
                  }}
                >
                  Confirmar Recepción
                </Button>
              </Stack>
            </div>
          </div>
        </>
      )}
    </>
  );
}
